<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="$can('update', 'player')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1"
            :to="{ name: 'update-player', params: { id: $route.params.id } }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
          <b-button
            v-if="$can('create', 'player')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1 ml-1"
            :to="{ name: 'create-player', query: { id: $route.params.id } }"
          >
            <feather-icon icon="CopyIcon" />
            {{ $t("button.clone") }}
          </b-button>
          <b-button
            v-if="
              data.parentId === userId &&
              ($can('create', 'player') || $can('edit', 'player'))
            "
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1 ml-1"
            @click="showDepositOrWithdrawModel"
          >
            <feather-icon icon="CreditCardIcon" />
            {{ $t("button.deposit") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view
        :fields="fields"
        v-model="data"
        :initValue="initData"
      ></n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
    ></deposit-or-withdraw>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonDelete from "@/components/NButtonDelete";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInputView from "@/components/NInputView";
import FormInput from "./formInput";
import DepositOrWithdraw from "@/views/account/depositOrWithdraw";

const PlayerRepository = Repository.get("player");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    DepositOrWithdraw,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
    userId: function () {
      if (this.$store.state.profile.id) {
        return this.$store.state.profile.id;
      }

      return this.userData?.id;
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: "male",
        username: null,
        email: null,
        password: null,
        parentId: null,
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      PlayerRepository.show(this.$route.params.id).then((response) => {
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          };
        }
      });
    },
    showDepositOrWithdrawModel(username) {
      this.$refs.depositWithdrawModel.changeData({
        type: "deposit",
        username: this.data.username,
        amount: 0,
        userId: this.$route.params.id,
        remark: null,
      });

      this.$refs.depositWithdrawModel.show(
        `${this.$t("field.deposit")} (${username})`
      );
    },
    deleteItem() {
      PlayerRepository.delete(this.$route.params.id)
        .then((response) => {
          this.back();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...FormInput];

    return { fields };
  },
};
</script>
